import React from 'react'
import Pic from "../../src/assets/Pic"


const DefaultPage = () => {
  return (
    <div className='App'>
      <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", rowGap: "5px", height: "100vh" }}>
        <div style={{ width: "100%" }}>
          <Pic />
        </div>
        <div>
         <h1>
           Page Not Found Error (404)
          </h1> 
        </div>
        <div>
          Please check your url or link.
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: "10px" }}>

          <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "row", margin: "20px 0px" }}>

            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default DefaultPage