import React, { useEffect, useContext, useState } from 'react'
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { useParams, useLocation } from 'react-router';
import { ZIM } from "zego-zim-web"
import AdLoader from './AdLoader';
import './Room.css'
import { environment } from '../environment/environments';

const Room = () => {
  const URL = environment.production
  console.log(URL)
  const room = useParams();
  console.log("userId", room.userID);
  const userID = room.userID.replace(/-/g, '');
  const roomID = room.roomID.replace(/-/g, '');
  const location = useLocation();
  const domainName = window.location.host.split('.')[0];
  const [meetInfo, setMeetInfo] = useState("")
  const [timeEnd, setTimeEnd] = useState(false);
  const [loding, setLoding] = useState(true);
  const [message, setMessage] = useState('')
  const [inTheRoom, setInTheRoom] = useState(false);
  const [bgBlack, setBgBlack] = useState(false);
  const [cvBtn, setCvBtn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isMentor, setIsMentor] = useState(false);
  const [cvLink, setCvLink] = useState('')
  const [isValid, setIsValid] = useState(false);
  const [hangUp, setHanUp] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isMeetEnd,setIsMeetEnd] = useState(false)
  const [meetEndMsg,setMeetEndMsg] = useState('The meeting has ended')

  ////////////////Org-state/////////////////////////

  const [loader, setLoader] = useState('');
  const [logoWhite, setLogoWhite] = useState('');
  const [logoBlack, setLogoBlack] = useState('');

  ////////////////For Timer///////////////////////////

  const [start, setStartTime] = useState(false)
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(30);
  const [isEle, setIsEle] = useState(false);

  useEffect(() => {
    if (start) {
      let timer = setInterval(() => {
        setSeconds(prev => prev - 1);
        if (seconds === 0) {
          setMinutes(prev => prev - 1)
          setSeconds(59)
        }
        if (minutes === 0 && seconds === 0) {
          setStartTime(false)
          redirectUrl()
        }
        // console.log("timer is running2", seconds);

      }, 1000);

      return () => clearInterval(timer)
    }

  }, [start, seconds, minutes]);

  // useEffect(() => {
  //   if (true) {
  //     let timer = setInterval(() => {
  //     alert("done")
  //     console.log("recodingg");
  //     }, 5000);

  //     return () => clearInterval(timer)
  //   }

  // }, []);

  useEffect(() => {
    if (start && minutes >= 0) {
      const timerDiv = document?.querySelector(".timerDiv") || document?.createElement("div");
      timerDiv.className = "timerDiv";
      timerDiv.style.cssText = 'display: flex; align-items: center; justify-content: flex-start; padding: 10px 8px; color: rgba(255, 255, 255, 0.8); font-weight: 700; font-size: 25px; line-height: 18px; background: #313443; border-radius: 12px;';
      timerDiv.innerHTML = `<span> ${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}</span>`

      const timeElement = document.querySelector(".dIzgYQV4CBbzZxzJbwbS");

      if (!timeElement?.querySelector(".timerDiv")) {
        timeElement?.appendChild(timerDiv);
      }
    }
  }, [seconds, minutes])

  ////////////////////////////////////////////////////////

  const query = new URLSearchParams(location.search);
  // const { zp, setZP } = useContext(ZPContext);

  //get name from query
  // const query = useQuery();
  const name = query.get("name");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const redirectUrl = () => {

    try {
      // fetch(`https://api.getprepped.in/api/getCallInfo/${room.roomID}/${room.userID}`)
      fetch(URL + `/api/endSession/${room.roomID}`)
        .then(response => response.json())
        .then(data => {
          fetch(URL + `/api/instanMeetInfo/${room.roomID}`)
            .then(response => response.json())
            .then((res) => {
              console.log("redirecting 101");
              window.location.href = (res?.Data?.redirectLink)
            }
            )
        })
        .catch((error) => {
          console.error('Error:', error);
          // Handle any errors here
        });
    } catch (err) {
      console.log(err)
    }
  }


  // const agendaCall = () => {
  //   try {
  //     // fetch(`https://api.getprepped.in/api/getCallInfo/${room.roomID}/${room.userID}`)
  //     // fetch(`https://mu-meet-backend-1.onrender.com/api/instanMeetInfo/${room.roomID}`)
  //     fetch(URL+`/api/instanMeetInfo/${room.roomID}`)
  //       .then(response => response.json())
  //       .then(data => {
  //         addAgenda(data?.Data?.call?.agenda ?? meetInfo?.call?.interviewAgenda?.agenda)
  //       })
  //       .catch((error) => {

  //         console.error('Error:', error);
  //         // Handle any errors here
  //       });
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  const addAgenda = (txt) => {
    const agendaDiv = document.createElement("div")
    agendaDiv.style.cssText = 'width: 100%; max-width: 70%; display: flex; align-items: center; justify-content: center; padding: 10px 40px; color: rgba(255, 255, 255, 0.8); font-weight: 400; font-size: 12px; line-height: 18px; background: rgb(49, 52, 67); border-radius: 1px;';
    //  agendaDiv.classList.add("agenda")
    agendaDiv.innerHTML = `<h1 style='display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden;    margin: 0; font-size: 14px; font-weight: 700;'>Agenda : ${txt ?? "N/A"} </h1>`
    const ele = document.querySelector(".dIzgYQV4CBbzZxzJbwbS");
    console.log(ele, 'appending')
    ele?.appendChild(agendaDiv);
    console.log("tan tan", txt);
  }

  ///////////////////End session Button////////////////////

  useEffect(() => {
    console.log("cvLink", cvLink);
    if (cvBtn && isMentor && cvLink !== '' && cvLink !== null) {
      const endSessionButton = document.querySelector(".endSessionButton") || document.createElement("button");
      endSessionButton.className = "Download CV";
      endSessionButton.style.cssText = 'display: flex;align-items: center;justify-content: center;padding: 10px 25px;color: rgb(255, 255, 255);font-weight: 700;font-size: 16px;line-height: 18px;background: #0055ff ;border-radius: 6px;text-transform: capitalize;cursor: pointer; border: none';
      endSessionButton.innerHTML = 'View CV';
      endSessionButton.onclick = () => {
        window.open(cvLink);
        console.log("cvlink", cvLink);
        // Replace with your API endpoint and data
        // fetch('https://your-api-endpoint.com', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify({
        //     // Your data here
        //   })
        // })
        // .then(response => response.json())
        // .then(data => console.log(data))
        // .catch((error) => {
        //   console.error('Error:', error);
        // });
        // setShowPopup(true)
        // alert("are you sure you want to end the call")
      };

      const timeElement = document.querySelector(".dIzgYQV4CBbzZxzJbwbS");

      if (!timeElement?.querySelector(".endSessionButton")) {
        timeElement?.appendChild(endSessionButton);
      }
    }

    if (cvBtn) {
      const parentDiv = document.querySelector(".ji5jASszKFf2CGCmbxEh")
      // Create a new <div> element
      const newDiv = document.createElement("div");

      // Set the styles for the new <div>
      newDiv.style.display = "flex";
      newDiv.style.alignItems = "center";
      newDiv.style.gap = "8px";
      newDiv.style.position = "absolute";
      newDiv.style.left = "40px";


      // Create an <img> element
      const imgElement = document.createElement("img");
      imgElement.src = "/assets/img/record.png";
      imgElement.alt = "rec";
      imgElement.style.width = "30px";

      // Create a <p> element
      const pElement = document.createElement("p");
      pElement.textContent = "Session is being recorded";
      pElement.style.color = "#fff";
      pElement.style.fontSize = "14px";
      pElement.style.fontWeight = "400";
      pElement.style.lineHeight = "120%";

      // Append the <img> and <p> elements to the new <div>
      newDiv?.appendChild(imgElement);
      newDiv?.appendChild(pElement);

      // Append the new <div> to the parentDiv
      parentDiv?.appendChild(newDiv);

    }

  }, [cvBtn])


  /////////////////////////////////////////////////////////

  let myMeeting = async (element) => {
    // generate Kit Token
    const appID = 1894334462;
    const serverSecret = "efeaf5de8cff4c82983d2b930b3cd04d";
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, userID, (name ?? 'User'));

    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    // setZP(zp); //

    zp.addPlugins({ ZIM });

    var zim = ZIM.getInstance();

    zim.on('receiveRoomMessage',
      function (zim, { messageList, fromConversationID }) {
        console.log("messageList", messageList, fromConversationID);
        // setStartTime(true)
        console.log("recivedmsg");
      }
    );

    // start the call
    zp.joinRoom({
      container: element,
      // sharedLinks: [
      //   {
      //     name: "meeting link",
      //     url: `${baseURL}/room/${room.roomID}/${room.userID}?name=${name}`,
      //   },
      // ],
      scenario: {
        mode: [ZegoUIKitPrebuilt.OneONoneCall], // To implement 1-on-1 calls, modify the parameter here to [ZegoUIKitPrebuilt.OneONoneCall].
      },
      // showRoomTimer: true,
      maxUsers: 3,
      screenSharingConfig: {
        resolution: ZegoUIKitPrebuilt.ScreenSharingResolution_720P
      },

      onJoinRoom: () => {
        // Add your custom logic
        // setTimeout(function () {
        //   redirectUrl()
        // }, 1800000);
        // setStartTime(true)
        // cvLinkCall()
        if (meetInfo?.call?.startedAt) {
          const timerT = new Date(meetInfo?.call?.startedAt)
          console.log("timerT", timerT);
          const currentTime = new Date();
          // Calculate the difference in minutes
          const differenceInMinutes = (currentTime - timerT) / (1000 * 60);

          if (differenceInMinutes < 30) {
            // Set remaining minutes here
            const remainingMinutes = 30 - differenceInMinutes;

            setMinutes(Math.floor(remainingMinutes));
            // setStartTime(true)
            console.log(`Less than 30 minutes remaining: ${remainingMinutes} minutes`);
            console.log("running the loop", differenceInMinutes);
          }

        }
        console.log("onJoinRoom");
        setInTheRoom(true)
        setCvBtn(true)
      },

      onInRoomTextMessageReceived: () => {
        console.log("alright its running", new Date());
      },

      onLeaveRoom: () => {
        // Add your custom logic
        setInTheRoom(false)
        setBgBlack(true)
        const button = document.querySelector(".IughcowXVrJ5wcOf6vH9");
        const homeButton = document.querySelector(".mCx2N1NwuMWObjjTeG0q");
        //create new button right after homeButton
        const newButton = document.createElement("button");
        newButton.innerHTML = "End Session";
        newButton.style.cssText = "margin-top: 20px; margin-bottom: 20px; cursor: pointer;width: 206px;height: 46px;font-weight: 600;font-size: 16px;line-height: 46px;border: 0;border-radius: 10px; color: #ffffff;background: #f00;display: flex;justify-content: center;align-items: center;";
        //creating a new button join
        const newButton2 = document.createElement("button");
        newButton2.innerHTML = "Rejoin";
        newButton2.style.cssText = "cursor: pointer;width: 206px;height: 46px;font-weight: 600;font-size: 16px;line-height: 46px;border: 0;border-radius: 10px; color: #ffffff;background: #0e77d3;display: flex;justify-content: center;align-items: center;";
        // now add it after homeButton
        if (isMentor) {
          homeButton.after(newButton);
          button.after(newButton2)
        }
        newButton.addEventListener("click", () => {
          // window.open(redirectLink ? redirectLink :"https://mastersunion.org/")
          // window.location.replace(baseURL);
          setShowPopup(true)
          redirectUrl()
        });
        newButton2.addEventListener("click", () => {
          window.location.reload()
        });
        if (button) {
          button.remove();
        }
        if (homeButton) {
          homeButton.remove();
        }
        console.log("onLeaveRoom");
      },
      branding: {
        logoURL: logoBlack, // The branding LOGO URL.
      },
    });

    if (hangUp) {
      console.log("hang-up call");
      zp.hangUp()
    }
  }

  useEffect(() => {

    // Call your API
    // fetch(`https://api.getprepped.in/api/getCallInfo/${room.roomID}/${room.userID}`)
    fetch(URL + `/api/instanMeetInfo/${room.roomID}`)
      .then(response => response.json())
      .then(data => {
        setLoding(false)
        // Use the data from the API
        setMeetInfo(data?.Data)
        setMessage(data?.Message)
        if (data?.Data?.meetingStatus === "cancelled") {
          setIsCancelled(true);
        }
        if (room.userID === data?.Data?.mentorId) {
          setIsValid(true)
          setIsMentor(true)
          setCvLink(data?.Data?.studentCv)
          console.log("user is mentor");
        } else if (room.userID === data?.Data?.menteeId) {
          console.log("user is mentee");
          setIsValid(true)
        } else if (room.userID === "55555f98-573b-48c8-abab-627f24f82622") {
          console.log("user is admin");
          setIsValid(true)
        }
        if (data?.Data?.Organization) {
          setLogoBlack(data?.Data?.Organization?.logoBlack)
          setLogoWhite(data?.Data?.Organization?.logoWhite)
          setLoader(data?.Data?.Organization?.loader)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  useEffect(() => {
    if (meetInfo?.meetingStatus === "completed") {
      setTimeEnd(true)

    }
    const endTime = new Date(meetInfo?.call?.endTime);
    const currentTime = new Date();

    if (currentTime > endTime) {
      // Current time is after end time
      console.log('The end time has passed.', endTime);
      // Perform your action here
      setTimeEnd(true)
    } else {
      if (meetInfo?.agenda) {
        setIsEle(true);
        // addingAgenda();
        // addAgenda(meetInfo?.call?.agenda ?? meetInfo?.call?.interviewAgenda?.agenda);
      }
    }

    if (meetInfo?.call?.mentor?.id === room.userID) {
      // setIsMentor(true)
    }

    if (meetInfo?.call?.startedAt) {
      const timerT = new Date(meetInfo?.call?.startedAt)
      console.log("timerT", timerT);
      const currentTime = new Date();
      // Calculate the difference in minutes
      const differenceInMinutes = (currentTime - timerT) / (1000 * 60);
      console.log("loop", differenceInMinutes);

      if (differenceInMinutes < 30) {
        // Set remaining minutes here
        // const remainingMinutes = 30 - differenceInMinutes;
        // const wholeNumberPart = Math.floor(remainingMinutes);
        // setMinutes(wholeNumberPart);
        const remainingTotalSeconds = Math.floor((30 - differenceInMinutes) * 60);
        const remainingMinutes = Math.floor(remainingTotalSeconds / 60);
        const remainingSeconds = remainingTotalSeconds % 60;
        setMinutes(remainingMinutes)
        setSeconds(remainingSeconds)
        // setStartTime(true)
        console.log(`Less than 30 minutes remaining: ${remainingMinutes} minutes`);
        console.log("running the loop", differenceInMinutes);
      } else {
        setTimeEnd(true)
      }


    }
  }, [meetInfo]);

  useEffect(() => {
    if (isEle) {

      const ele = document.querySelector(".pOvRwHj19chJGkgemUH3") || document.querySelector(".jF_DhwXwBFswm5nphXHX");
      // console.log("element", ele)
      if (ele?.classList?.contains("jF_DhwXwBFswm5nphXHX")) {
        ele.style.minHeight = "100vh";
        ele.style.justifyContent = "center";
      }
      // const ele = document.querySelector(".pOvRwHj19chJGkgemUH3")||document.querySelector(".jF_DhwXwBFswm5nphXHX");
      var newDiv3 = document.createElement("div");
      newDiv3.className = "dQDNvqxeCXpZG5yOs9Ou";
      newDiv3.innerHTML = `
       <div style="
           display: flex;
           width: 100%;
           align-items: flex-start;
           font-size: 14px;
           margin-bottom: 10px;
           font-weight: 700;
           color: #141414;
           gap: 6px;
       "> 
       Agenda : <span style=" font-weight: 500;
           display: -webkit-box;
           -webkit-line-clamp: 2;
           -webkit-box-orient: vertical;
           overflow: hidden;
           width: 210px;
           font-weight: 500;
           max-width: 100px
          //  color: #737373;
           "> ${(meetInfo?.agenda) ?? ("N/A")}</span>
       </div>
    `;
      ele?.appendChild(newDiv3);

      var newDiv4 = document.createElement("div");
      newDiv4.className = "dQDNvqxeCXpZG5yOs9Ou";
      newDiv4.innerHTML = `
    <div style="
        display: flex;
        width: 100%;
        align-items: flex-start;
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: 600;
        color: grey;
        gap: 6px;
        max-width: 340px;
    "> 
    Please be informed that this session will be recorded for future reference.
    </div>
`;
      ele?.appendChild(newDiv4);
    }
  }, [isEle])

  const endMeeting = () => {
    // setIsSessionEnded(true);
    fetch(`https://apidinero.mastersunion.org/v2/api/org/roomId/${room.roomID}/sendEvaluationFormToInterviewer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        mentorId: room?.userID,
        menteeId: meetInfo?.menteeId
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setHanUp(true)
        setShowPopup(false)
      })
      .catch((error) => {
        console.error('Error:', error);
      });

  }
  
  useEffect(()=>{
    window.addEventListener("click", (e) => {
      if(e.target.classList?.contains('QeMJj1LEulq1ApqLHxuM')) {
        setIsMeetEnd(true)
      }
    })
  
    return()=>{
      window.removeEventListener("click", (e) => {
        if(e.target.classList?.contains('QeMJj1LEulq1ApqLHxuM')) {
          setIsMeetEnd(true)
          
        }
      })
    }
    
  },[inTheRoom])

  useEffect(() => {
  window.addEventListener("click", (e) => {

    if(e.target.classList?.contains('TTgLs8cpg66Z6CXgHGVA')) {
      setMeetEndMsg('You have left the meeting.')
    }
  })

  }, [isMeetEnd])

  useEffect(()=>{
    const leaveText = document.querySelector(".lflaXazrPGAK9SbOcNoC");
    if(leaveText !== null) leaveText.innerHTML= meetEndMsg

  },[meetEndMsg,inTheRoom])

console.log("state is changed", meetEndMsg);



  return (
    <>
      {(meetInfo && !timeEnd && isValid && !isCancelled) ?
        (
          <div className="myCallContainer" style={{ position: "relative" }}>

            {showPopup ?
              <div className="popupWrapper logoutPop">
                <div className="popupContainer LogoutPopup" style={{ background: "#ffffffc9", borderColor: "#ffffffc9" }}>
                  <div className="popupHeader" style={{ paddingTop: "28px" }}>
                    <span className="close"><img src="/assets/svg/close.svg" alt="" /></span>
                  </div>
                  <div className="popupBody flexbox">
                    <div className="popBodyWrap flexbox">
                      <img src="/assets/svg/alert.svg" alt="" />
                    </div>
                    <p className="flexbox" style={{ fontSize: "14px", fontWeight: '600', margin: "0" }}>You are being redirected to feedback page </p>
                    <section class="dotscontainerLoad" >
                      <div class="dotLoad"></div>
                      <div class="dotLoad"></div>
                      <div class="dotLoad"></div>
                      <div class="dotLoad"></div>
                      <div class="dotLoad"></div>
                    </section>
                    {/* <div className="buttonWrapper">
                      <button className="button RedFillButton" style={isSessionEnded ? { backgroundColor: "#d1d5d8", border: "none", cursor: 'default'} : null} disabled={isSessionEnded} onClick={endMeeting}>
                        <div className="overLapLoad undefined"></div>
                        Yes, I’m Sure
                      </button>

                      <button className="button GreyFillButton"  style={isSessionEnded ? { backgroundColor: "#d1d5d8", border: "none", cursor: 'default'} : null} disabled={isSessionEnded} onClick={() => setShowPopup(false)}>
                        <div className="overLapLoad undefined"></div>
                        No, Cancel
                      </button>
                    </div> */}
                  </div>
                </div>
              </div> : ""}

            <div style={{ position: "absolute", top: "20px", left: "2%" }}>
              <img src={bgBlack ? logoBlack : logoWhite} alt="" style={{ width: "75%" }} />
            </div>
            <div
              ref={myMeeting}
              style={{ width: '100vw', height: '100vh' }}
            >
            </div>
            <div style={{ position: "fixed", bottom: "20px", right: "0%" }}>
              {inTheRoom ? "" : <img src={bgBlack ? "https://cdn.mastersunion.org/assets/GetPreppedPowerLogoblack.svg" : "https://cdn.mastersunion.org/assets/getPreppedPowerlogowhite.svg"} alt="" style={{ width: "75%" }} />}
            </div>

          </div>
        ) :

        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", minHeight: "100vh", flexDirection: "column", gap: "20px" }}>
          <img src={loding ? "" : logoWhite} alt="" style={{ width: "18%" }} />
          <h1 style={{ fontSize: "18px", fontWeight: "600", color: "#141414", textAlign: "center" }}>{loding ? <AdLoader domain={domainName} /> : ((meetInfo?.meetingStatus !== 'completed' && meetInfo?.meetingStatus !== 'cancelled') ? "Link is invalid please check " : (isCancelled ? "The meeting is cancelled" : "The meeting has been completed"))}</h1>
        </div>

      }
    </>
  )
}

export default Room