import Room from '../src/Pages/Room';
import { Route,Routes } from 'react-router';
import DefaultPage from './Pages/DefaultPage';
import './App.css';

function App() {
  return (

      <Routes>
        <Route path='/room/:roomID/:userID' element={<Room />} />
        <Route path='*' element={<DefaultPage/>}></Route>
      </Routes>
  );
}

export default App;
