import React from 'react'

const AdLoader = ({domain}) => {
    return (
        // <section class="dotscontainerLoad">
        //     <div class="dotLoad"></div>
        //     <div class="dotLoad"></div>
        //     <div class="dotLoad"></div>
        //     <div class="dotLoad"></div>
        //     <div class="dotLoad"></div>
        // </section>
       <div>
        <img src={ domain === "tetr-meet" ? "https://cdn.getprepped.in/tetrPreLoader.gif" : "https://cdn.mastersunion.org/assets/img/master2.svg"} alt="" style={{maxWidth: "100%"}} />
       </div>
    )
}

export default AdLoader